import React from "react";

export default (props) =>
    <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24" height="24" viewBox="0 0 16 16"
        fillRule="evenodd"
        fill="none"
    >
        <path fill="none" d="M0 0h16v16H0z"/><path d="M8.004 12a.714.714 0 01-.522-.239L2.203 6.208a.735.735 0 01-.203-.5.69.69 0 011.182-.5l4.822 5.07 4.814-5.07A.706.706 0 0113.311 5c.392 0 .689.306.689.708a.696.696 0 01-.196.5L8.526 11.76a.717.717 0 01-.522.239z" fill="#76756F"/>
    </svg>


